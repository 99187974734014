import React from "react"

import { I_Bullet, I_Headline, I_Options } from "common/types"
import { Bullets } from "components/Bullets"
import { Grid, GridItem } from "components/Grid"
import { Headline } from "components/Headline"
import { Image, Props as I_Image } from "components/Image"

import { TeaserImage as TI } from "./styles"

interface Props {
  options: I_Options
  body: {
    bullets?: I_Bullet[]
    headline: I_Headline
    image: I_Image
  }
}

export const TeaserImage: React.FC<Props> = props => {
  const {
    options: { headlineSize, paddingTop, paddingBottom },
    body: { headline, bullets, image },
  } = props

  const renderBullets = (bullets: I_Bullet[]) => Bullets({ bullets })

  const renderHeadline = (headline: I_Headline, headlineSize: any) => (
    <TI.Header>{Headline({ headline, headlineSize })}</TI.Header>
  )

  const renderImage = (image: I_Image) => (
    <TI.Image>{Image({ ...image })}</TI.Image>
  )

  return (
    <Grid
      modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
      spacingTop={paddingTop}
      spacingBottom={paddingBottom}
    >
      <GridItem>
        <TI.Block>
          <TI.Content>
            {renderHeadline(headline, headlineSize)}
            <TI.Bullets>{bullets && renderBullets(bullets)}</TI.Bullets>
          </TI.Content>
          {renderImage(image)}
        </TI.Block>
      </GridItem>
    </Grid>
  )
}
