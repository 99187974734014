import styled from "styled-components"
import { Bullets as B } from "components/Bullets/styles"
import { breaks, gaps, modifiers as mods } from "common/styles"
import { color } from "common/settings"

const {
  font: {
    sizes: { advert, paragraph },
  },
} = mods

interface I_BulletsSlider {
  tileWidth: number | string
}

export const BulletsSlider = styled.div<I_BulletsSlider>`
  padding: ${gaps.xxl} 0 ${gaps.xxxl};
  width: 100%;
  max-width: 100vw;
  overflow-x: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &:-webkit-scrollbar {
    display: none;
  }

  ${breaks.lg.up} {
    padding: ${gaps.xxxl} 0;
  }

  & ${B.Block} {
    padding-right: ${gaps.xxxxl};
    width: ${({ tileWidth }) =>
      tileWidth === "auto" ? "auto" : tileWidth + "px"};

    ${breaks.xl.up} {
      flex: 1;
      width: auto !important;
    }

    & p {
      ${advert};
      font-weight: 200;
      color: ${color.middle};

      &:nth-of-type(2) {
        ${paragraph};
        margin-top: ${gaps.xl};
        color: ${color.dark};
      }
    }
  }
`
