import React from "react"

import { I_Options } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Paragraph as P } from "./styles"

interface Props {
  options: I_Options
  body: {
    paragraphI: string
  }
}

export const Paragraph: React.FC<Props> = props => {
  const {
    options: { paddingTop, paddingBottom },
    body: { paragraphI },
  } = props

  return (
    <Grid
      modifiers={["spacingTop", "spacingBottom"]}
      spacingTop={paddingTop}
      spacingBottom={paddingBottom}
    >
      <GridItem>
        <P.Block dangerouslySetInnerHTML={{ __html: paragraphI }} />
      </GridItem>
    </Grid>
  )
}
