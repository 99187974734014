import React from "react"
import { v4 as uuidv4 } from "uuid"
import { graphql } from "gatsby"
import { ContentLayout } from "layouts/Content/index.layout"
import { components } from "./components"

interface Props {
  location: Location
  data: any
}

const PageBuilder: React.FC<Props> = props => {
  const {
    data: {
      content: { lang, content },
    },
  } = props

  const renderComponents = (item: any) => {
    const { type } = item

    if (typeof components[type] !== undefined) {
      const Component = components[type]
      return <Component key={uuidv4()} {...item} />
    }
  }

  return (
    <ContentLayout lang={lang} location={props.location}>
      {content && content.map((item: any) => renderComponents(item))}
    </ContentLayout>
  )
}

export default PageBuilder

export const data = graphql`
  query ($slug: String!) {
    content: contentJson(slug: { eq: $slug }) {
      title
      lang
      slug
      description
      content {
        type
        options {
          reverse
          headlineSize
          modifiers
          paddingTop
          paddingBottom
        }
        body {
          bullets {
            keyStat
            headline
            description
          }
          items {
            title
            image {
              alt
              src
            }
          }
          logos {
            title
            caption
            image {
              alt
              src
            }
          }
          rows {
            circles {
              modifiers
              title
              image {
                alt
                src
              }
            }
          }
          image {
            alt
            src
          }
          imageII {
            alt
            src
          }
          headline {
            I
            II
          }
          paragraphI
          paragraphII
        }
      }
    }
  }
`
