import styled from "styled-components"
import { motion as m } from "framer-motion"
import { breaks } from "common/styles"

interface I_BulletsSliderLane {
  width: number | string
}

export const BulletsSliderLane = styled(m.div)<I_BulletsSliderLane>`
  display: flex;
  flex-wrap: wrap;
  cursor: grab;

  width: ${({ width }) => {
    console.log(width)
    return width === "auto" ? "auto" : width + "px"
  }};

  ${breaks.xl.up} {
    width: 100% important;
  }
`
