import { breaks } from "common/styles"
import styled from "styled-components"

export const TeaserImageHeader = styled.div`
  max-width: 100%;

  ${breaks.sm.up} {
    max-width: 80%;
  }

  ${breaks.md.up} {
    max-width: 60%;
  }

  ${breaks.xl.up} {
    max-width: 70%;
  }
`
