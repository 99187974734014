import { TeaserImages as Block } from "./TeaserImages.styles"
import { TeaserImagesContent as Content } from "./TeaserImagesContent.styles"
import { TeaserImagesImage as Image } from "./TeaserImagesImage.styles"
import { TeaserImagesList as List } from "./TeaserImagesList.styles"

export const TeaserImage = {
  Block,
  Content,
  Image,
  List,
}
