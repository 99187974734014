import styled from "styled-components"
import { modifiers as mods } from "common/styles/modifiers"
import { gaps } from "common/styles"

const {
  font: {
    sizes: { headline, subline, title, paragraph },
  },
} = mods

export const Paragraph = styled.div`
  & h1 {
    ${headline}

    padding-bottom: ${gaps.xxxl};
  }
  & h3 {
    ${subline};
    font-weight: 400;
  }

  & h4 {
    ${paragraph};
    font-weight: 400;
  }

  & h3 + h4 {
    padding-top: ${gaps.xl};
  }

  & h4 + h3 {
    padding-top: ${gaps.xxxl};
  }

  & h4 + p {
    padding-top: ${gaps.xl};
  }

  & p {
    ${paragraph};
  }

  & h3 + p {
    padding-top: ${gaps.xl};
  }

  & p + h3 {
    padding-top: ${gaps.xxl};
  }
`
