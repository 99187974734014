import styled from "styled-components"
import { gaps } from "common/styles"

export const TeaserImagesList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: ${gaps.xxxl} 0 0;
  padding: 0;
  width: 100%;
  list-style: none;

  & li {
    flex: 1;
    display: flex;
    margin: ${gaps.l} 0 0;
    padding: 0;
    width: 100%;
    cursor: pointer;

    & div {
      display: flex;
      width: 100%;
    }

    & div div {
      flex: 1;
      text-align: right;
    }

    & div div:first-of-type {
      flex: 0 0 10%;
    }
  }
`
