import { BulletsSlider as Block } from "./BulletsSlider.styles"
import { BulletsSliderLane as Lane } from "./BulletsSliderLane.styles"
import {
  BulletsSliderPogress as Element,
  BulletsSliderPogressIndex as Index,
} from "./BulletsSliderProgress.styles"

export const BulletsSlider = {
  Block,
  Lane,
  Progress: {
    Element,
    Index,
  },
}
