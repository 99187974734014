import styled, { css } from "styled-components"
import { gaps } from "common/styles"

import { modifiers as mods } from "common/styles/"
import { applyModifiers } from "common/functions"

type T_TeaserTextParagraph = keyof typeof Modifiers
interface I_TeaserTextParagraph {
  modifiers?: T_TeaserTextParagraph[]
}

const {
  font: {
    sizes: { paragraph, body },
  },
} = mods

const Modifiers = {
  noHeadline: () => css`
    & p {
      ${paragraph}
      line-height: 1.65;
    }
  `,
}

export const TeaserTextParagraph = styled.div<I_TeaserTextParagraph>`
  & p {
    ${paragraph}
    line-height: 1.65;
  }

  & p + p {
    margin-top: ${gaps.xl};
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
