import React from "react"
import { v4 as uuidv4 } from "uuid"
import { I_Bullet } from "common/types"

import { Text } from "components/Text"
import { I_Text } from "components/Text/styles"
import { Bullets as B } from "./styles"

interface Props {
  bullets: I_Bullet[]
}

export const Bullets: React.FC<Props> = props => {
  const { bullets } = props

  const bulletStyles: I_Text = {
    type: "p",
    modifiers: ["title"],
    weight: 400,
  }

  const renderText = (config: I_Text, content: React.ReactNode) =>
    Text({ ...config, children: content })

  const renderBullet = (bullet: I_Bullet) => {
    const { keyStat, headline, description } = bullet
    return (
      <B.Block key={uuidv4()}>
        {renderText({ ...bulletStyles, modifiers: ["subline"] }, keyStat)}
        {renderText(
          {
            ...bulletStyles,
            modifiers: ["tertiary", "spacingTop", "spacingBottom"],
            spacingTop: "s",
            spacingBottom: "m",
          },
          headline
        )}
        {renderText({ ...bulletStyles, modifiers: ["small"] }, description)}
      </B.Block>
    )
  }

  return <>{bullets.map(bullet => renderBullet(bullet))}</>
}
