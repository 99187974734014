import React from "react"

import { I_Headline, I_Options } from "common/types"
import { Headline } from "components/Headline"
import { Grid, GridItem } from "components/Grid"
import { Logo } from "components/Logo"
import { Text } from "components/Text"

import { Intro as I } from "./styles"

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
  }
}

export const Intro: React.FC<Props> = props => {
  const {
    options: { headlineSize },
    body: { headline },
  } = props

  const renderLogo = () => <Logo />

  const renderHeadline = (headline: I_Headline, headlineSize: string) =>
    Headline({ headline, headlineSize })

  return (
    <I.Block>
      <Grid modifiers={["maxWidth"]}>
        <GridItem responsive={{ lg: { span: 6 } }}>{renderLogo()}</GridItem>
        <GridItem
          responsive={{ lg: { span: 6 } }}
          modifiers={["flex", "alignEnd"]}
        >
          <Text modifiers={["spacingBottom"]} spacingBottom={"s"}>
            {renderHeadline(headline, headlineSize)}
          </Text>
        </GridItem>
      </Grid>
    </I.Block>
  )
}
