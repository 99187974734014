import styled from "styled-components"
import { color } from "common/settings"

export const TeaserImageContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  position: relative;
  z-index: 2;

  & h2,
  h4,
  p,
  span {
    color: ${color.inverted} !important;
  }
`
