import styled, { css } from "styled-components"

import { applyModifiers, pxToRem } from "common/functions"
import { color } from "common/settings"
import { breaks, gaps } from "common/styles"
import { rgba } from "polished"

export type T_DiagramCircle = keyof typeof Modifiers

interface I_DiagramCircle {
  modifiers?: T_DiagramCircle[]
}

const Modifiers = {
  isCenter: () => css`
    border: 0;

    & svg {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 480%;
      opacity: 0.48;
      transform: translate(-50%, -50%);
    }

    & ${DiagramCircleImage} {
      width: 80%;
    }
  `,
}

export const DiagramCircle = styled.div<I_DiagramCircle>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: ${gaps.xxxl} auto 0;
  width: ${pxToRem(224)};
  height: ${pxToRem(224)};

  border-radius: 50%;
  border: 1px solid ${color.inverted};

  ${breaks.md.up} {
    width: ${pxToRem(224)};
    height: ${pxToRem(224)};
  }

  ${breaks.xl.up} {
    width: ${pxToRem(258)};
    height: ${pxToRem(258)};
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`

export const DiagramCircleImage = styled.div`
  margin: 0 auto;
  width: 48%;
`
