import styled from "styled-components"
import { rgba } from "polished"
import { motion as m } from "framer-motion"
import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { gaps } from "common/styles"

export const BulletsSliderPogress = styled.div`
  position: relative;
  margin: ${gaps.xxl} auto 0;
  width: ${pxToRem(120)};
  height: ${pxToRem(2)};
  background-color: ${rgba(color.light, 0.32)};
  border-radius: ${pxToRem(4)};
`

export const BulletsSliderPogressIndex = styled(m.div)`
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -3px;
  margin-bottom: ${gaps.m};
  width: ${pxToRem(60)};
  height: ${pxToRem(6)};
  background-color: ${color.dark};
  border-radius: ${pxToRem(4)};
  transform: translateY(-50%);
`
