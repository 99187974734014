import React from "react"

import { I_Headline, I_Options } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { T_OrderPoints, T_GridPoints } from "components/Grid/styles/GridItem"
import { Headline } from "components/Headline"
import { Image, Props as I_Image } from "components/Image"
import { TeaserImage as TI } from "components/Teasers/TeaserImages/styles"
import { TeaserText as TT } from "components/Teasers/TeaserText/styles"
import { Text } from "components/Text"
import { Svg } from "components/Svg"

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
    paragraphI: any
    paragraphII: string
    image: I_Image
    imageII: I_Image
  }
}

export const TeaserBlockquote: React.FC<Props> = props => {
  const {
    options: { modifiers, paddingTop, paddingBottom, headlineSize },
    body: { headline, paragraphI, paragraphII, image, imageII },
  } = props

  const columns: {
    [key: string]: { responsive: T_GridPoints; order?: T_OrderPoints }
  } = {
    content: {
      responsive: { md: { span: 8 }, xl: { span: 9 } },
      order: { xs: { order: 1 }, sm: { order: 1 }, md: { order: 2 } },
    },
    image: {
      responsive: { md: { span: 3, start: 2 }, xl: { span: 2, start: 2 } },
      order: { xs: { order: 2 }, sm: { order: 2 }, md: { order: 1 } },
    },
  }

  const renderParagraph = (paragraph: any) => (
    <Text
      modifiers={["spacingTop", "spacingBottom"]}
      spacingTop={"xl"}
      spacingBottom={"xl"}
    >
      <TT.Paragraph
        modifiers={headline ? undefined : ["noHeadline"]}
        dangerouslySetInnerHTML={{ __html: paragraph }}
      />
    </Text>
  )

  const renderCite = (paragraph: string) => (
    <Text type={"p"} modifiers={["small"]}>
      {paragraph}
    </Text>
  )

  return (
    <Grid modifiers={["maxWidth"]}>
      <GridItem>
        <TI.Block
          modifiers={
            modifiers
              ? [...modifiers, "imageContainerLogo", "blockquote"]
              : ["backgroundWhite", "imageContainerLogo", "blockquote"]
          }
        >
          <Grid
            modifiers={["spacingTop", "spacingBottom"]}
            spacingTop={paddingTop}
            spacingBottom={paddingBottom}
          >
            <GridItem
              responsive={columns.image.responsive}
              order={columns.image.order}
            >
              <Image alt={image.alt} src={image.src} />
              {imageII && (
                <>
                  <br />
                  <Image alt={imageII.alt} src={imageII.src} />
                </>
              )}
            </GridItem>
            <GridItem
              responsive={columns.content.responsive}
              order={columns.content.order}
            >
              <blockquote>
                {!modifiers?.includes("noQuote") && <Svg type="quote" />}
                <TI.Content>
                  {headline && Headline({ headline, headlineSize })}
                  {renderParagraph(paragraphI)}
                  {renderCite(paragraphII)}
                </TI.Content>
              </blockquote>
            </GridItem>
          </Grid>
        </TI.Block>
      </GridItem>
    </Grid>
  )
}
