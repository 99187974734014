import styled, { css } from "styled-components"
import { color } from "common/settings"
import { breaks, gaps } from "common/styles"
import { applyModifiers } from "common/functions"

export type T_Diagram = keyof typeof Modifiers
interface I_Diagram {
  modifiers?: T_Diagram[]
}

const Modifiers = {
  isCenter: () => css`
    border: 0;
  `,
}

export const Diagram = styled.div<I_Diagram>`
  padding: ${gaps.xxxl} 0;
  background-color: ${color.dark};
  background-image: url(./pattern-II.png);

  & h2 span {
    color: ${color.inverted} !important;
  }

  ${breaks.lg.up} {
    padding: ${gaps.xxxxl} 0;
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
