import styled, { css } from "styled-components"
import { applyModifiers } from "common/functions"
import { breaks, gaps } from "common/styles"

type T_DiagramRow = keyof typeof Modifiers

interface I_DiagramRow {
  modifiers?: T_DiagramRow[]
}

const Modifiers = {
  singleItem: () => css`
    justify-content: center;
  `,
}

export const DiagramRow = styled.div<I_DiagramRow>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  ${breaks.md.up} {
    flex-direction: row;
  }

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
