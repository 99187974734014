import styled from "styled-components"
import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { gaps } from "common/styles"

export const TeaserImagesImage = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${pxToRem(560)};
  background-color: ${color.dark};
  background-image: url(./pattern-II.png);

  & img {
    object-fit: contain;
  }
`
