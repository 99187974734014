import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image"

export interface Props {
  src: any
  classes?: string
  title?: string
  alt?: string
}

function queryImages() {
  return useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            relativePath
            childImageSharp {
              gatsbyImageData(
                width: 1600
                quality: 100
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  `)
}

export const Image: React.FC<Props> = props => {
  const { src, title, classes, alt } = props
  const data = queryImages()

  const match = useMemo(
    () =>
      data.images.edges.find(({ node }: any) => `${src}` === node.relativePath),
    [data, src]
  )

  if (!match) return null
  const { node } = match
  const image = getImage(node)

  if (!image) return null

  return (
    <Img
      className={classes || ""}
      image={image}
      alt={alt || title || "Layer image"}
    />
  )
}
