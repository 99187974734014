import React from "react"
import { Grid, GridItem } from "components/Grid"
import { I_Headline, I_Options } from "common/types"
import { Headline } from "components/Headline"
import { Text } from "components/Text"

import { TeaserText as TT } from "./styles"

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
    paragraphI?: any
    paragraphII?: any
  }
}

export const TeaserText: React.FC<Props> = props => {
  const {
    options: { headlineSize, paddingTop, paddingBottom },
    body: { headline, paragraphI, paragraphII },
  } = props

  const renderGridItem = (el: JSX.Element) => (
    <GridItem responsive={{ md: { span: 6 } }}>{el}</GridItem>
  )

  const renderParagraph = (paragraph: any) => (
    <Text modifiers={["color"]} color="middle">
      <TT.Paragraph dangerouslySetInnerHTML={{ __html: paragraph }} />
    </Text>
  )

  return (
    <TT.Block>
      <Grid
        modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
        spacingTop={paddingTop}
        spacingBottom={paddingBottom}
      >
        {renderGridItem(
          <>
            {Headline({ headline, headlineSize })}
            {paragraphI && (
              <>
                <br />
                <br />
                {renderParagraph(paragraphI)}
              </>
            )}
          </>
        )}
        {renderGridItem(<>{renderParagraph(paragraphII)}</>)}
      </Grid>
    </TT.Block>
  )
}
