import styled from "styled-components"
import { breaks, gaps } from "common/styles"

export const DiagramFigure = styled.div`
  margin: 0 auto;
  padding: ${gaps.xxxl} 0;

  ${breaks.md.up} {
    max-width: 100%;
  }

  ${breaks.lg.up} {
    max-width: 80%;
  }
`
