import React, { useEffect, useRef, useState } from "react"
import { useMotionValue, useTransform } from "framer-motion"

import { I_Bullet, I_Options } from "common/types"
import { breakpoints } from "common/settings"
import useEventListener from "hooks/useEventListener"
import useWindowSize from "hooks/useWindowSize"
import { Grid } from "components/Grid/styles/Grid"
import { GridItem } from "components/Grid"
import { Bullets } from "../"
import { BulletsSlider as BS } from "./styles"

interface Props {
  options: I_Options
  body: {
    bullets: I_Bullet[]
  }
}

export const BulletsSlider: React.FC<Props> = props => {
  const {
    options: { paddingTop, paddingBottom },
    body: { bullets },
  } = props
  const refContainer = useRef<HTMLDivElement>(null)
  const [left, setLeft] = useState<number>(0)
  const [tileWidth, setTileWidth] = useState<number>(0)
  const [dragConstraints, setDragConstraints] = useState<number>(0)
  const { width } = useWindowSize()

  const scrollX = useMotionValue(0)
  const scrollWidth = useTransform(scrollX, [0, -dragConstraints], [0, 60])

  useEffect(() => {
    setDragConstraints(left + tileWidth * bullets.length - width)
  }, [left])

  const onResize = () => {
    if (refContainer && refContainer.current) {
      const left = refContainer.current.getBoundingClientRect().left
      setLeft(left)
    }
  }

  console.log(paddingTop)

  useEventListener("resize", onResize)

  useEffect(() => {
    if (width < breakpoints.sm) {
      setTileWidth(300)
    }
    if (width >= breakpoints.sm && width < breakpoints.md) {
      setTileWidth(320)
    }
    if (width >= breakpoints.md && width < breakpoints.lg) {
      setTileWidth(400)
    }
    if (width >= breakpoints.lg) {
      setTileWidth(480)
    }
  }, [width])

  useEffect(() => {
    onResize()
  }, [])

  const renderBullets = (bullets: I_Bullet[]) => Bullets({ bullets })

  const renderProgress = () => (
    <BS.Progress.Element>
      <BS.Progress.Index style={{ x: scrollWidth }}></BS.Progress.Index>
    </BS.Progress.Element>
  )

  return (
    <Grid
      modifiers={
        width < breakpoints.xl
          ? ["noPadding", "spacingTop", "spacingBottom"]
          : ["maxWidth", "spacingTop", "spacingBottom"]
      }
      spacingTop={paddingTop}
      spacingBottom={paddingBottom}
    >
      <GridItem>
        <Grid modifiers={["maxWidth"]}>
          {width >= breakpoints.xl && (
            <GridItem>
              <div ref={refContainer}></div>
            </GridItem>
          )}
        </Grid>
        <BS.Block tileWidth={width >= breakpoints.xl ? "auto" : tileWidth}>
          <BS.Lane
            // drag={"x"}
            // dragConstraints={{
            //   left: -dragConstraints,
            //   right: 0,
            // }}
            // style={{ x: scrollX }}
            width={
              width >= breakpoints.xl
                ? "auto"
                : left + tileWidth * bullets.length
            }
          >
            <div style={{ width: left }} />
            {renderBullets(bullets)}
          </BS.Lane>
          {/* {renderProgress()} */}
        </BS.Block>
      </GridItem>
    </Grid>
  )
}
