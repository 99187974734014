import React from "react"
import { I_Headline, I_Options } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Headline } from "components/Headline"

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
  }
}

export const HeroHeadline: React.FC<Props> = props => {
  const {
    options: { modifiers, headlineSize, paddingTop, paddingBottom },
    body: { headline },
  } = props

  const renderHeadline = (
    headline: I_Headline,
    headlineSize: any,
    modifiers?: any[]
  ) => Headline({ headline, headlineSize, modifiers })

  return (
    <Grid
      modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
      spacingTop={paddingTop}
      spacingBottom={paddingBottom}
    >
      <GridItem>{renderHeadline(headline, headlineSize, modifiers)}</GridItem>
    </Grid>
  )
}
