import { TeaserImage as Block } from "./TeaserImage.styles"
import { TeaserImageBullets as Bullets } from "./TeaserImageBullets.styles"
import { TeaserImageContent as Content } from "./TeaserImageContent.styles"
import { TeaserImageHeader as Header } from "./TeaserImageHeader.styles"
import { TeaserImageImage as Image } from "./TeaserImageImage.styles"

export const TeaserImage = {
  Block,
  Bullets,
  Content,
  Header,
  Image,
}
