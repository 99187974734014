import styled from "styled-components"
import { Bullets as B } from "components/Bullets/styles"
import { breaks, gaps } from "common/styles"

export const TeaserImageBullets = styled.div`
  display: flex;
  justify-content: space-around;
  flex-direction: column;

  ${breaks.sm.up} {
    flex-direction: row;
  }

  & ${B.Block} {
    flex: 1;

    ${breaks.sm.up} {
      flex: 0 0 45%;
    }
  }

  & ${B.Block} + ${B.Block} {
    margin-top: ${gaps.xxl};

    ${breaks.sm.up} {
      margin-top: 0;
    }
  }
`
