import React from "react"
import { I_Headline } from "common/types"
import { Text } from "components/Text"
import { I_Text } from "components/Text/styles"

import { Headline as H } from "./styles"
import { T_Headline } from "./styles/Headline.styles"

interface Props {
  headline: I_Headline
  headlineSize?: any
  modifiers?: T_Headline[]
}

export const Headline: React.FC<Props> = props => {
  const {
    headline: { I, II },
    headlineSize,
    modifiers,
  } = props

  const headlineStyles: I_Text = {
    type: "span",
    modifiers: ["fontWeight", "block", "color"],
    color: "dark",
    weight: 500,
  }

  const renderText = (config: I_Text, content: React.ReactNode) =>
    Text({ ...config, children: content })

  return (
    <H.Block modifiers={modifiers}>
      {renderText(
        { type: "h2", modifiers: [headlineSize || "tertiary"] },
        <>
          {renderText(headlineStyles, I)}
          {II && <br /> &&
            renderText({ ...headlineStyles, color: "middle", weight: 300 }, II)}
        </>
      )}
    </H.Block>
  )
}
