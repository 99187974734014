import styled from "styled-components"

import { pxToRem } from "common/functions"
import { breaks, gaps } from "common/styles"

export const TeaserImage = styled.div`
  position: relative;
  display: flex;
  padding: ${gaps.xxl} ${gaps.xl};

  height: ${pxToRem(560)};
  overflow: hidden;

  ${breaks.lg.up} {
    padding: ${gaps.xxxl} ${gaps.xxl};
    height: ${pxToRem(720)};
  }

  ${breaks.xxl.up} {
    padding: ${gaps.xxxl} ${gaps.xxl};
    height: ${pxToRem(1024)};
  }
`
