import styled, { css } from "styled-components"

import { applyModifiers, pxToRem } from "common/functions"
import { color } from "common/settings"
import { breaks, gaps } from "common/styles"

import { TeaserImagesImage } from "./TeaserImagesImage.styles"
import { TeaserText as TT } from "components/Teasers/TeaserText/styles"

export type T_TeaserImages = keyof typeof Modifiers

export interface I_TeaserImage {
  modifiers?: T_TeaserImages[]
}

const Modifiers = {
  backgroundInverted: () => css`
    background-color: ${color.dark};

    & ${TT.Paragraph} {
      & p {
        color: ${color.inverted};
      }
    }

    & h2 span {
      color: ${color.inverted} !important;
    }

    & p {
      color: ${color.inverted};
    }
  `,
  backgroundWhite: () => css`
    & ${TeaserImagesImage} {
      background-color: ${color.inverted};
    }
  `,
  blockquote: () => css`
    background-image: url(./pattern-II.png);

    & blockquote {
      margin: 0;
    }

    & .gatsby-image-wrapper {
      display: block;
      margin: 0 auto;
      max-width: ${pxToRem(160)};
    }

    & svg {
      margin-bottom: ${gaps.xxl};
    }
  `,
  imageContainerLogo: () => css`
    & ${TeaserImagesImage} {
      height: ${pxToRem(240)};

      ${breaks.md.up} {
        height: auto;
      }

      & .gatsby-image-wrapper {
        margin: 0 auto;
        width: 100%;
        max-width: ${pxToRem(260)};

        ${breaks.md.up} {
          max-width: ${pxToRem(400)};
        }
      }
    }
  `,
}

export const TeaserImages = styled.div<I_TeaserImage>`
  display: block;

  ${({ modifiers }) => applyModifiers(Modifiers, modifiers)}
`
