import { Diagram as Block } from "./Diagram.styles"
import {
  DiagramCircle as Element,
  DiagramCircleImage as Image,
} from "./DiagramCircle.styles"
import { DiagramFigure as Figure } from "./DiagramFigure.styles"
import { DiagramRow as Row } from "./DiagramRow.styles"

export const Diagram = {
  Block,
  Circle: {
    Element,
    Image,
  },
  Figure,
  Row,
}
