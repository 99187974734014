import styled from "styled-components"
import { lighten } from "polished"

import { pxToRem } from "common/functions"
import { color } from "common/settings"
import { gaps } from "common/styles"

export const PartnersItem = styled.div`
  padding: 0 0 ${gaps.xxl};
  border-bottom: ${pxToRem(1)} solid ${lighten(0.24, color.light)};
`
