import { BulletsSlider } from "components/Bullets/BulletsSlider"
import { HeroHeadline } from "components/Headline/HeroHeadline"
import { Diagram } from "components/Diagram"
import { Intro } from "components/Intro"
import { ImageWrapper } from "components/Image/ImageWrapper"
import { Partners } from "components/Partners"
import { Paragraph } from "components/Paragraph"
import { TeaserImage } from "components/Teasers/TeaserImage"
import { TeaserImages } from "components/Teasers/TeaserImages"
import { TeaserText } from "components/Teasers/TeaserText"
import { TeaserTextImage } from "components/Teasers/TeaserTextImage"
import { TeaserBlockquote } from "components/Teasers/TeaserBlockquote"

export const components: any = {
  bulletsSlider: BulletsSlider,
  diagram: Diagram,
  headline: HeroHeadline,
  intro: Intro,
  image: ImageWrapper,
  partners: Partners,
  paragraph: Paragraph,
  teaserBlockquote: TeaserBlockquote,
  teaserImage: TeaserImage,
  teaserImages: TeaserImages,
  teaserText: TeaserText,
  teaserTextImage: TeaserTextImage,
}
