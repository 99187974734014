import React, { useState } from "react"
import { v4 as uuidv4 } from "uuid"

import { I_Headline, I_Options } from "common/types"
import { Grid, GridItem } from "components/Grid"
import { Headline } from "components/Headline"
import { Image, Props as I_Image } from "components/Image"
import { Text } from "components/Text"

import { TeaserImage as TI } from "./styles"

interface I_Item {
  title: string
  image: I_Image
}

interface Props {
  options: I_Options
  body: {
    headline: I_Headline
    items: I_Item[]
  }
}

export const TeaserImages: React.FC<Props> = props => {
  const [activeState, setActiveState] = useState<number>(0)
  const {
    options: { paddingTop, paddingBottom },
    body: { headline, items },
  } = props

  const renderItem = (item: I_Item, index: number) => (
    <li key={uuidv4()} onClick={() => setActiveState(index)}>
      {Text({
        modifiers: ["paragraph", "color"],
        color: activeState === index ? "middle" : "light",
        children: (
          <>
            <div>0{index + 1}.</div> <div>{item.title}</div>
          </>
        ),
      })}
    </li>
  )

  return (
    <TI.Block>
      <Grid
        modifiers={["maxWidth", "spacingTop", "spacingBottom"]}
        spacingTop={paddingTop}
        spacingBottom={paddingBottom}
      >
        <GridItem responsive={{ md: { span: 7 }, xl: { span: 8 } }}>
          <TI.Content>
            {Headline({ headline })}
            {items && (
              <TI.List>
                {items.map((item, index) => renderItem(item, index))}
              </TI.List>
            )}
          </TI.Content>
        </GridItem>
        <GridItem responsive={{ md: { span: 5 }, xl: { span: 4 } }}>
          <TI.Image>
            <Image src={items[activeState].image.src} />
          </TI.Image>
        </GridItem>
      </Grid>
    </TI.Block>
  )
}
