import styled from "styled-components"
import { rgba } from "polished"
import { color } from "common/settings"

export const TeaserImageImage = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      ${rgba(color.contrast, 0)},
      ${rgba(color.contrast, 0.24)}
    );
    //background: ${rgba(color.contrast, 0.16)};
  }

  & .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  & img {
    object-fit: cover;
  }
`
