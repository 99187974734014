import React from "react"

import { Image, Props as I_Image } from "components/Image"
import { Grid, GridItem } from "components/Grid"

interface Props {
  body: {
    image: I_Image
  }
}

export const ImageWrapper: React.FC<Props> = props => {
  const {
    body: { image },
  } = props
  return (
    <Grid modifiers={["maxWidth"]}>
      <GridItem>
        <Image {...image} />
      </GridItem>
    </Grid>
  )
}
